<template>
  <div class="wrapper">
    <div class="tag" v-if="realNameStatus === 0">
      <p>
        您的商户信息未成功完成实名认证/实名过期，为了保障您的账户安全及享受更多的服务，请尽快完成。
      </p>
      <el-button type="text" @click="handleHref">实名认证 &#62;</el-button>
    </div>
    <div class="upload-wrapper">
      <div class="title">
        <p>上传票面</p>
        <el-popover placement="right" width="900" trigger="hover">
          <img src="./image/example.jpg" style="width: 900px" alt="example" />
          <el-button slot="reference" type="text" class="btn">
            查看示例
          </el-button>
        </el-popover>
      </div>
      <div class="upload-form">
        <div class="upload">
          <el-upload
            ref="upload"
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :data="uploadData"
            :auto-upload="false"
            :on-change="handleUploadChange"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="form">
          <p class="tip">请校验识别信息，如有错误可手动修改</p>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            inline
            label-width="94px"
            label-position="left"
            size="small"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item label="承兑人名称" prop="acceptorName">
                  <el-input v-model="form.acceptorName" />
                </el-form-item>
                <el-form-item label="行号" prop="accepterBank">
                  <el-input v-model="form.accepterBank" />
                </el-form-item>
                <el-form-item label="票据号码" prop="billNo">
                  <el-input v-model="form.billNo" @input="handleInput" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="票面金额" prop="billAmt">
                  <el-input v-model="form.billAmt">
                    <span
                      slot="suffix"
                      style="padding-right: 10px; color: #333"
                    >
                      元
                    </span>
                  </el-input>
                </el-form-item>
                <el-form-item label="到期日" prop="maturityDate">
                  <el-date-picker
                    v-model="form.maturityDate"
                    type="date"
                    placeholder="请选择到期日"
                  />
                </el-form-item>
                <el-form-item label="子票区间" prop="draftDate" v-if="isShow">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-input
                        v-model="form.draftStartInterval"
                        :maxlength="16"
                        placeholder="开始"
                      ></el-input>
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        :maxlength="16"
                        v-model="form.draftEndInterval"
                        placeholder="结束"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="small"
                    :loading="trialLoading"
                    @click="handleTrial"
                  >
                    开始试算
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <!--银票银行展示区域-->
    <div class="list" v-if="isSilver">
      <div class="list-item" v-for="(item, index) in bankList" :key="index">
        <div class="title">
          <div class="left">
            <span class="lines">{{ item.bankDesc }}</span>
            <el-divider direction="vertical" />
            <span class="time">交易时间：{{ getTime(item.bankCode) }}</span>
          </div>
          <div class="right">
            {{ getRemark1(item.bankCode) }}
            <el-divider direction="vertical" />
            {{ getRemark2(item.bankCode) }}
          </div>
        </div>
        <div class="bank">
          <div class="icon">
            <img :src="getImageUrl(item.bankCode)" alt="银行" />
          </div>
          <div class="rate">
            <div class="bank-title">贴现率（%）</div>
            <div class="bank-value">
              <template v-if="trialSilverStatus === 0">-</template>
              <template v-else>
                <span v-if="item.sts !== 0" class="desc">
                  {{ item.stsDesc }}
                </span>
                <template v-else>{{ item.discountRateFormat }}</template>
              </template>
            </div>
          </div>
          <div class="amount">
            <div class="bank-title">预计成交价（元）</div>
            <div class="bank-value">
              {{
                trialSilverStatus === 0
                  ? '-'
                  : item.expectDealAmountFormat
                  ? item.expectDealAmountFormat
                  : '-'
              }}
            </div>
          </div>
          <div class="btn">
            <!--未登录-->
            <el-button
              type="primary"
              plain
              size="small"
              v-if="!$store.state.username"
              @click="handleSignBtn"
              :disabled="
                item.sts === 15 ||
                item.sts === 11 ||
                item.sts === 12 ||
                item.sts === 13 ||
                item.sts === 14
              "
            >
              立即签约
            </el-button>
            <template v-else>
              <!--未实名-->
              <template v-if="!realNameStatus">
                <el-button type="primary" plain size="small" @click="backToTop">
                  立即签约
                </el-button>
              </template>
              <!--已实名-->
              <template v-else>
                <!--签约未成功-->
                <div v-if="item.creditStatus !== '0'">
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="
                      item.bankCode === 'COMMERCIAL'
                        ? handleCommercialApplySignBtn()
                        : handleApplySignBtn(item.bankCode)
                    "
                    :disabled="
                      item.sts === 15 ||
                      item.sts === 11 ||
                      item.sts === 12 ||
                      item.sts === 13 ||
                      item.sts === 14
                    "
                  >
                    立即签约
                  </el-button>
                  <p class="tip" :class="signStatusColor(item.creditStatus)">
                    {{ signStatus(item.creditStatus) }}
                  </p>
                </div>
                <!--签约成功-->
                <template v-else>
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    :disabled="!isDiscount(item.creditStatus, item.sts)"
                    @click="handleSilverDiscount(item)"
                  >
                    立即贴现
                  </el-button>
                  <p class="tip" :class="signStatusColor(item.creditStatus)">
                    {{ signStatus(item.creditStatus) }}
                  </p>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--商票银行列表展示区域-->
    <div class="list" v-if="!isSilver">
      <div class="list-item" v-for="(item, index) in bankList" :key="index">
        <div class="title">
          <div class="left">
            <span class="lines">{{ item.bankDesc }}</span>
            <el-divider direction="vertical" />
            <span class="time">交易时间：{{ getTime(item.bankCode) }}</span>
          </div>
          <div class="right">
            {{ getRemark1(item.bankCode) }}
            <el-divider direction="vertical" />
            {{ getRemark2(item.bankCode) }}
          </div>
        </div>
        <div class="bank">
          <div class="icon">
            <img :src="getImageUrl(item.bankCode)" alt="银行" />
          </div>
          <div class="rate">
            <div class="bank-title">年化收益率（%）</div>
            <div class="bank-value">
              <template v-if="trialCommercialStatus === 0">-</template>
              <template v-else>
                <div v-if="item.resCode !== '0'">
                  {{ formatPercentage(item.discountYearRate) }}
                  <div class="tip">
                    <p>折价率 {{ formatPercentage(item.discountRate) }} %</p>
                    <p>
                      另需支付保费 {{ formatAmount(item.guaranteeAmount) || 0 }}
                      元
                      <el-popover placement="right" width="300" trigger="hover">
                        <span>
                          <el-row style="font-size: 12px">
                            <el-col :span="8">预计到账金额</el-col>
                            <el-col :span="2">-</el-col>
                            <el-col :span="6">担保费用</el-col>
                            <el-col :span="2">=</el-col>
                            <el-col :span="6">参考金额</el-col>
                          </el-row>
                          <el-row style="font-size: 12px">
                            <el-col :span="8">
                              {{ formatAmount(item.discountAmount) }}
                            </el-col>
                            <el-col :span="2">&nbsp;</el-col>
                            <el-col :span="6">
                              {{ formatAmount(item.guaranteeAmount) || 0 }}
                            </el-col>
                            <el-col :span="2">=</el-col>
                            <el-col :span="6">
                              {{
                                formatAmount(
                                  item.discountAmount - item.guaranteeAmount,
                                )
                              }}
                            </el-col>
                          </el-row>
                        </span>
                        <i class="el-icon-warning-outline" slot="reference"></i>
                      </el-popover>
                    </p>
                  </div>
                </div>
                <span v-else style="font-size: 15px">
                  {{ item.responseDesc }}
                </span>
              </template>
            </div>
          </div>
          <div class="amount">
            <div class="bank-title">预计到账金额（元）</div>
            <div class="bank-value">
              {{ formatAmount(item.discountAmount) }}
            </div>
          </div>
          <div class="btn">
            <!--未登录-->
            <el-button
              type="primary"
              plain
              size="small"
              v-if="!$store.state.username"
              @click="handleSignBtn"
              :disabled="item.resCode === '0'"
            >
              立即签约
            </el-button>
            <!--已登录-->
            <template v-else>
              <!--未实名-->
              <template v-if="!realNameStatus">
                <el-button type="primary" plain size="small" @click="backToTop">
                  立即签约
                </el-button>
              </template>
              <!--已实名-->
              <template v-else>
                <!--未签约-->
                <el-button
                  v-if="item.openAccountStatus !== '0'"
                  type="primary"
                  plain
                  size="small"
                  @click="handleCommercialApplySignBtn"
                  :disabled="item.resCode === '0'"
                >
                  立即签约
                </el-button>
                <!--已签约-->
                <el-button
                  v-else
                  type="primary"
                  plain
                  size="small"
                  @click="handleCommercialDiscount"
                  :disabled="item.resCode === '0'"
                >
                  立即贴现
                </el-button>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBankList,
  upload,
  getUserInfo,
  trialSilver,
  apply,
  trialCommercial,
  applySign,
  silverDiscount,
  getJsTicket,
} from './api'
import config from '@/config'
import dayjs from 'dayjs'
import numeral from 'numeral'

export default {
  data() {
    this.statusMap = {
      0: '已开通',
      10: '未申请',
      20: '申请审核中',
      25: '预审核通过',
      30: '审核驳回',
    }

    const checkDraftDate = (rule, value, callback) => {
      if (!this.form.draftStartInterval || !this.form.draftEndInterval) {
        return callback(new Error('子票区间不能为空'))
      }
      callback()
    }
    return {
      hrefUrl: '',
      imageUrl: '',
      // 商票测试数据
      // form: {
      //   acceptorName: '银企直连普通测试67',
      //   billAmt: '111',
      //   billNo: '210523430001720210709971364966',
      //   maturityDate: '2023-07-07',
      //   accepterBank: '21001720404052502096',
      //   acceptorBankAccNo: '',
      // },
      //银票测试数据
      // form: {
      //   acceptorName: '美的集团财务有限公司',
      //   billAmt: '806267.38',
      //   billNo: '190758100007020190125338627713',
      //   maturityDate: '2023-07-07',
      //   accepterBank: '907581000070',
      //   acceptorBankAccNo: '',
      // },
      form: {
        acceptorName: '',
        billAmt: '',
        billNo: '',
        maturityDate: '',
        accepterBank: '',
        acceptorBankAccNo: '',
        draftStartInterval: '',
        draftEndInterval: '',
      },
      rules: {
        acceptorName: [
          { required: true, message: '输入框不能为空', trigger: 'blur' },
        ],
        accepterBank: [
          { required: true, message: '输入框不能为空', trigger: 'blur' },
        ],
        billAmt: [
          { required: true, message: '输入框不能为空', trigger: 'blur' },
        ],
        billNo: [
          { required: true, message: '输入框不能为空', trigger: 'blur' },
        ],
        maturityDate: [
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
        draftDate: [
          { required: true, validator: checkDraftDate, trigger: 'blur' },
        ],
      },
      bankList: [],
      uploadData: { imgType: 1, base64Str: '' },
      trialLoading: false,
      realNameStatus: 1,
      // 银票试算 0： 未试算 1：已试算
      trialSilverStatus: 0,
      // 商票试算 0： 未试算 1：已试算
      trialCommercialStatus: 0,
      // 银票为 true，商票为 false
      isSilver: true,
      isShow: false,
    }
  },
  mounted() {
    this._getBankList()
    this._getUserInfo()
  },
  methods: {
    handleInput() {
      const firstVal = this.form.billNo.charAt(0)
      if (['5', '6'].includes(firstVal)) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    getRemark1(name) {
      return this.renderFn([
        { name: 'FM', value: '极速签约' },
        { name: 'COMMERCIAL', value: '支持700+白名单' },
        { name: 'WHLH', value: '首单50万以下(含)优惠6BP' },
        { name: 'WZ', value: '支持同法人多企业签约' },
        { name: 'SX', value: '当日签约' },
        { name: 'ZB', value: '支持600+白名单' },
      ]).get(name)
    },
    getRemark2(name) {
      return this.renderFn([
        { name: 'FM', value: '秒速放款' },
        { name: 'COMMERCIAL', value: '当日签约放款' },
        { name: 'WHLH', value: '50万-500万(含)优惠2BP' },
        { name: 'WZ', value: '100以下历史前二笔优惠2BP' },
        { name: 'SX', value: '即时放款' },
        { name: 'ZB', value: '当日签约放款' },
      ]).get(name)
    },
    getTime(name) {
      return this.renderFn([
        { name: 'FM', value: '08:30-20:00' },
        { name: 'COMMERCIAL', value: '09:00-18:00' },
        { name: 'WHLH', value: '08:30-20:00' },
        { name: 'WZ', value: '08:15-19:30' },
        { name: 'SX', value: '09:30-17:00' },
        { name: 'ZB', value: '09:00-16:30' },
      ]).get(name)
    },
    renderFn(list) {
      const map = new Map()
      list.forEach(item => {
        map.set(item.name, item.value)
      })
      return map
    },
    // 格式化金额
    formatAmount(amount) {
      if (amount) {
        return numeral(amount).divide(100).format('0,0.00')
      }
      return ''
    },
    // 格式化为百分比
    formatPercentage(value) {
      return numeral(value).format('0.00%').substr(0, 4)
    },
    // 已签约成功，需要判断是否可贴现
    isDiscount(creditStatus, sts) {
      if (sts == null) {
        return false
      }
      return creditStatus === '0' && sts.toString() === '0'
    },
    // 商票签约
    async handleCommercialApplySignBtn() {
      try {
        const result = await getJsTicket()
        const { jdSignature, saltStr, timestamp } = result
        const loginUrl = config.baseUrl + '/login'
        const backUrl = config.baseUrl + '/bill-financing'
        if (['dev', 'test'].includes(config.env)) {
          window.jpmt.env = 'development'
        }
        const url = window.jpmt.computePageUrl({
          name: encodeURIComponent('commercial-contract'),
          loginUrl,
          backUrl,
          platformId: config.platformId,
          platformUserId: this.userId,
          signature: jdSignature,
          timestamp,
          saltStr,
        })
        if (url) {
          this.$storage.setItem('commercialSignUrl', url)
          await this.$router.push('/commercial-sign')
        } else {
          console.error('商票签约 url 获取错误')
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 银票贴现
    async handleSilverDiscount(data) {
      try {
        const {
          billNo,
          billAmt,
          accepterBank,
          maturityDate,
          draftStartInterval = '',
          draftEndInterval = '',
        } = this.form
        const { bankCode, discountRate, jdFeeAmount } = data

        const submitData = {
          bankCode,
          discountRate,
          jdFeeAmount,
          draftAmount: numeral(billAmt).multiply(100).value(),
          acceptorBankCnasp: accepterBank,
          draftNo: billNo,
          maturityDate: dayjs(maturityDate).format('YYYYMMDD'),
          draftStartInterval,
          draftEndInterval,
          isSplit: ['5', '6'].includes(billNo.charAt(0)) ? 1 : 2,
        }
        // const {
        //   responseCode,
        //   responseDesc,
        //   platformOrderNo,
        //   needSupplyBackground,
        // } = await silverDiscount(submitData)
        const { platformOrderNo } = await silverDiscount(submitData)

        this.targetMaoShen({ platformOrderNo, bankCode })
        // if (
        //   typeof needSupplyBackground === 'number' &&
        //   needSupplyBackground === 0
        // ) {
        //   this.targetMaoShen({ platformOrderNo, bankCode })
        //   return
        // }
        // if (responseCode === '000000') {
        //   await this.$router.push(
        //     `/discount-detail/${platformOrderNo}?isDiscount=1`,
        //   )
        // } else {
        //   this.$message.error(responseDesc)
        // }
      } catch (err) {
        console.error(err)
      }
    },
    // 跳转贸审
    async targetMaoShen({ platformOrderNo, bankCode }) {
      try {
        const result = await getJsTicket()
        const { jdSignature, saltStr, timestamp } = result
        const loginUrl = config.baseUrl + '/login'
        // const backUrl = config.baseUrl + '/bill-financing'
        const backUrl =
          config.baseUrl + `/discount-detail/${platformOrderNo}?isDiscount=1`
        // if (['dev', 'test'].includes(config.env)) {
        //   window.jpmt.env = 'development'
        // }
        const url = window.jpmt.computePageUrl({
          name: 'place-an-order-mt',
          querys: {
            cb: backUrl,
            endorseTradeNo: platformOrderNo,
            bankCode,
          },
          loginUrl,
          backUrl,
          platformId: config.platformId,
          platformUserId: this.userId,
          signature: jdSignature,
          timestamp,
          saltStr,
        })
        console.log('backUrl', backUrl)
        console.log('platformOrderNo', platformOrderNo)
        console.log('bankCode', bankCode)
        console.log('loginUrl', loginUrl)
        console.log('backUrl', backUrl)
        console.log('platformId', config.platformId)
        console.log('platformUserId', this.userId)
        console.log('signature', jdSignature)
        console.log('timestamp', timestamp)
        console.log('saltStr', saltStr)
        if (url) {
          console.log(url)
          window.location.href = url
          // console.log(url)
          // this.$storage.setItem('commercialMaoshen', url)
          // await this.$router.push('/commercial-maoshen')
        } else {
          console.error('贸审 url 获取错误')
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 商票贴现
    async handleCommercialDiscount() {
      const params = {
        acceptorName: this.form.acceptorName,
        draftNo: this.form.billNo,
        maturityDate: this.form.maturityDate,
        draftAmount: this.form.billAmt,
      }
      const getApply = await apply(params)
      if (getApply.responseCode === '000000') {
        try {
          const result = await getJsTicket()
          const { jdSignature, saltStr, timestamp } = result
          const loginUrl = config.baseUrl + '/login'
          const backUrl = config.baseUrl + '/bill-financing'
          if (['dev', 'test'].includes(config.env)) {
            window.jpmt.env = 'development'
          }
          const url = window.jpmt.computePageUrl({
            name: encodeURIComponent('commercial-list'),
            querys: {
              cb: backUrl,
            },
            loginUrl,
            backUrl,
            platformId: config.platformId,
            platformUserId: this.userId,
            signature: jdSignature,
            timestamp,
            saltStr,
          })
          if (url) {
            this.$storage.setItem('commercialDiscountUrl', url)
            await this.$router.push('/commercial-discount')
          } else {
            console.error('商票贴现 url 获取错误')
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    backToTop() {
      this.$message.warning('请完成实名认证之后再签约')
      const content = document.getElementById('content')
      if (content) {
        content.scrollTop = 0
      }
    },
    async handleApplySignBtn(bankCode) {
      try {
        const { creditApplyUrl } = await applySign({ bankCode })
        this.$storage.setItem('signUrl', creditApplyUrl)
        await this.$router.push('/sign')
      } catch (err) {
        console.error(err)
      }
    },
    signStatusColor(status) {
      if (status === '0') {
        return 'green'
      }

      return 'red'
    },
    signStatus(status) {
      return this.statusMap[status]
    },
    // 点击立即签约按钮
    handleSignBtn() {
      // 未登录直接跳转登录页面
      if (!this.$store.state.username) {
        this.$router.push('/login')
      }
    },
    getImageUrl(name) {
      const imageName = ['FM', 'SX', 'WHLH', 'WZ', 'ZB', 'COMMERCIAL']
      if (imageName.includes(name)) {
        return require(`./image/${name}.png`)
      }
    },
    // 试算
    async handleTrial() {
      try {
        const valid = await this.$refs.form.validate()
        if (valid) {
          const maturityDate = this.form.maturityDate
          const r = dayjs(maturityDate).isBefore(dayjs())
          if (r) return this.$message.warning('票据到期日不能早于今日')
          // 1|5|6: 银票  2: 商票
          this.isSilver = ['1', '5', '6'].includes(this.form.billNo.charAt(0))
          this.trialLoading = true

          this.isSilver
            ? await this._trialSilver()
            : await this._trialCommercial()
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 银票试算
    async _trialSilver() {
      const submitData = { ...this.form }
      delete submitData.acceptorBankAccNo

      const result = await trialSilver(
        {
          ...submitData,
          billAmt: numeral(submitData.billAmt).multiply(100).value(),
          maturityDate: dayjs(submitData.maturityDate).format('YYYYMMDD'),
          draftStartInterval: dayjs(submitData.draftStartInterval).format(
            'YYYY-MM-DD',
          ),
          draftEndInterval: dayjs(submitData.draftEndInterval).format(
            'YYYY-MM-DD',
          ),
        },
        this.$store.state.username,
      )
      if (result && Array.isArray(result.mtBankList)) {
        this.bankList = result.mtBankList.map(item => {
          let discountRateFormat =
            item.discountRate && numeral(item.discountRate).format('0.00%')
          if (discountRateFormat) {
            discountRateFormat = discountRateFormat.substr(
              0,
              discountRateFormat.length - 1,
            )
          }
          return {
            ...item,
            discountRateFormat,
            expectDealAmountFormat:
              item.expectDealAmount &&
              numeral(item.expectDealAmount).divide(100).format('0,0.00'),
          }
        })
      }
      this.trialLoading = false
      this.trialSilverStatus = 1
    },
    // 商票试算
    async _trialCommercial() {
      const submitData = { ...this.form }

      delete submitData.accepterBank
      const result = await trialCommercial(
        {
          draftNo: submitData.billNo,
          acceptorName: submitData.acceptorName,
          acceptorBankAccNo: submitData.acceptorBankAccNo,
          draftAmount: numeral(submitData.billAmt).multiply(100).value(),
          maturityDate: dayjs(submitData.maturityDate).format('YYYY-MM-DD'),
        },
        this.$store.state.username,
      )
      if (result) {
        this.bankList = [{ ...result, bankCode: 'COMMERCIAL' }]
      }
      this.trialLoading = false
      this.trialCommercialStatus = 1
    },
    async _getUserInfo() {
      try {
        if (this.$store.state.username) {
          const { userId, status } = await getUserInfo()
          if (process.env.VUE_APP_NODE_ENV === 'prod') {
            this.hrefUrl = `http://10.122.83.51:6060/receipt-api/realname?userId=${userId}`
          } else {
            this.hrefUrl = `${config.baseUrl}/receipt-api/realname?userId=${userId}`
          }
          this.realNameStatus = status
          this.userId = userId
        }
      } catch (err) {
        console.error(err)
      }
    },
    handleHref() {
      window.location.href = this.hrefUrl
    },
    async _getBankList() {
      try {
        const result = await getBankList(this.$store.state.username)
        this.bankList = result.mtBankList
      } catch (err) {
        console.error(err)
      }
    },
    async handleUploadChange(file) {
      try {
        const fileList = ['image/jpeg', 'image/png']
        const isImage = fileList.includes(file.raw.type)

        if (!isImage) {
          return this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!')
        }

        this.uploadData.base64Str = await this.getBase64(file.raw)
        const result = await upload({ ...this.uploadData })
        Object.keys(result).forEach(key => {
          if (key === 'maturityDate') {
            this.form[key] = dayjs(result[key]).format('YYYY-MM-DD')
          } else if (key === 'billAmt') {
            this.form[key] = numeral(result[key]).divide(100).value()
          } else if (key === 'accepterName') {
            this.form['acceptorName'] = result[key]
          } else {
            this.form[key] = result[key]
          }
        })
        this.imageUrl = URL.createObjectURL(file.raw)
        this.$message.success('上传成功')
      } catch (err) {
        this.$refs.upload.clearFiles()
        console.error(err)
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult.substr(imgResult.indexOf(',') + 1))
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  .tag {
    display: flex;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #333;
    background: #e8f1ff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    padding: 0 40px;

    p {
      margin-right: 80px;
    }

    a {
      color: #337eff;
    }
  }

  .upload-wrapper {
    padding: 48px 0 48px 40px;
    background-color: #fff;
    margin-top: 10px;

    .title {
      display: flex;
      align-items: center;
      border-left: 8px solid #2c68ff;
      padding-left: 16px;
      box-sizing: border-box;
      height: 32px;
      margin-bottom: 32px;

      p {
        font-size: 24px;
        font-weight: 600;
        margin-right: 8px;
      }

      .btn {
        position: relative;
        top: 4px;
      }
    }

    .upload-form {
      display: flex;

      .upload {
        margin-right: 32px;
        .avatar-uploader {
          border: 1px solid #d2c7c7;
          border-radius: 6px;
          margin-top: 20px;
          ::v-deep.el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background-color: #ebebeb;

            &:hover {
              border-color: #409eff;
            }
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 144px;
            height: 144px;
            line-height: 144px;
            text-align: center;
          }

          .avatar {
            width: 144px;
            height: 144px;
            display: block;
          }
        }
      }

      .form {
        .tip {
          font-size: 14px;
          color: #999;
          line-height: 22px;
          margin-bottom: 25px;
        }

        ::v-deep.el-input {
          width: 320px;
        }

        .start-btn {
          position: relative;
          top: 52px;
        }
      }
    }
  }

  .list {
    padding: 52px 40px;
    box-sizing: border-box;
    background: #fff;
    margin: 16px 0 40px 0;
    border-top: 4px solid #2c68ff;

    .list-item {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding-bottom: 40px;
      margin-bottom: 16px;

      .title {
        display: flex;
        font-size: 12px;
        margin-bottom: 32px;

        .left {
          height: 24px;
          background: #e8f1ff;
          border-radius: 8px 0 24px 0;
          padding: 0 43px 0 16px;
          margin-right: 32px;

          .lines {
            line-height: 24px;
            color: #666;
          }
        }
        .right {
          line-height: 24px;
          color: #666;
        }
      }

      .bank {
        display: flex;
        box-sizing: border-box;
        padding: 0 24px;

        .icon {
          width: 352px;
        }

        .rate {
          width: 240px;
        }
        .amount {
          width: 334px;
        }

        .bank-title {
          font-size: 14px;
          color: #666;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .bank-value {
          position: relative;
          font-size: 28px;
          color: #f84d57;
          line-height: 24px;

          .tip {
            position: absolute;
            top: -52px;
            left: 120px;

            p {
              color: #939090;
              font-size: 11px;
            }
          }

          .desc {
            font-size: 14px;
            color: #999;
          }
        }

        .tip {
          margin-top: 10px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
        }

        .red {
          color: red;
        }

        .green {
          color: green;
        }
      }
    }
  }

  ::v-deep.el-divider--vertical {
    margin: 0 30px;
  }
}
</style>
