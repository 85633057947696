import request from '@/utils/request'

export const getBankList = isLogin => {
  let prefix = '/pipe-api'
  if (isLogin) {
    prefix = '/receipt-api'
  }
  return request.get(`${prefix}/receipt/bank/list`)
}

export const upload = params => request.post('/pipe-api/receipt/upload', params)

export const getUserInfo = () => request.get('/manager-api/user/getUser')

// 试算银票
export const trialSilver = (params, isLogin) => {
  let prefix = '/pipe-api'
  if (isLogin) {
    prefix = '/receipt-api'
  }
  return request.post(`${prefix}/receipt/trial`, params)
}
//申请商票
export const apply = params =>
  request.post('/receipt-api/receipt/apply/order', params)

// 试算商票
export const trialCommercial = (params, isLogin) => {
  let prefix = '/pipe-api'
  if (isLogin) {
    prefix = '/receipt-api'
  }
  return request.post(`${prefix}/receipt/trial/business`, params)
}

// 申请签约
export const applySign = params =>
  request.post('/receipt-api/receipt/sign/apply', params)

// 银票贴现
export const silverDiscount = params =>
  request.post('/receipt-api/receipt/create/order', params)

export const getJsTicket = () =>
  request.get('/receipt-api/receipt/get/jsTicket')

export const commercialDiscount = params =>
  request.post('/receipt-api/receipt/apply/order', params)
